<template>
  <div class="msg-center-wrap">
    <MsgTemplete :msg-type="1" class="top-wrap" />
    <MsgTemplete :msg-type="3" class="middle-wrap" />
    <MsgTemplete :msg-type="2" class="bottom-wrap" />
  </div>
</template>

<script>
import MsgTemplete from './components/msgTemplete';
export default {
  components: { MsgTemplete },
};
</script>
<style lang='scss' scoped>
.msg-center-wrap{
  padding: 15px;
  border-radius: 4px;
  background: #fff;
  height: 100%;
  .top-wrap,.middle-wrap,.bottom-wrap{
    height: 33.33%;
  }
}
</style>
