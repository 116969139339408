import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
// 获取通知列表
export const transportOrderMsgList = (data, options = {}) => {
  return request.post(`/auv/order/msg/list`, pickTrue(data)).then((res) => {
    return res;
  });
};

// 已读
export const postMessageRead = (data) => {
  return request.post(`/auv/order/msg/state/update`, data).then((res) => {
    return res;
  });
};
// 消息删除
export const removeMsg = (data) => {
  return request.post(`/auv/order/msg/flag/update`, data).then((res) => {
    return res;
  });
};
